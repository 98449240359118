<template>
  <div>
    <v-progress-circular v-if="loadingFilters && showLoader" :indeterminate="true" :rotate="0" :size="32" :width="4"
      color="primary"></v-progress-circular>
    <div v-if="!loadingFilters">
      <v-row dense>
        <v-col v-if="OrdersFilter">
          <v-select class="minfield" outlined dense :label="$t('filters-period-order-state')" ref="period"
            v-model="filters.filterByOrderState" :items="orders_states" item-text="name" item-value="id"
            :disabled="disabled">
          </v-select>
        </v-col>
        <v-col v-if="TicketsFilter">
          <v-select class="minfield" outlined dense :label="$t('filters-period-ticket-state')" ref="period"
            v-model="filters.filterByTicketState" :items="tickets_states" item-text="name" item-value="id"
            :disabled="disabled">
          </v-select>
        </v-col>

        <v-col v-if="OrdersFilter">
          <v-select class="minfield" outlined dense :label="$t('filters-period-order-source')" ref="period"
            v-model="filters.filterByOrderSource" :items="orders_sources" item-text="name" item-value="id"
            :disabled="disabled">
          </v-select>
        </v-col>
        <v-col v-if="InvoicesFilter || OrdersFilter">
          <v-text-field class="minfield" outlined dense :label="$t('filters-text')" :value="filters.filterByText"
            @change="value => (filters.filterByText = value)" :disabled="disabled">
          </v-text-field>
        </v-col>
        <v-col v-if="InvoicesFilter">
          <v-select class="minfield" outlined dense :label="$t('filters-doc-type')"
            v-model="filters.filterByDocumentType" :items="document_types" item-text="name" item-value="id"
            :disabled="disabled">
          </v-select>
        </v-col>

        <v-col v-if="InvoicesFilter">
          <v-select class="minfield" outlined dense :label="$t('filters-period-invoice-state')" ref="period"
            v-model="filters.filterByInvoiceState" :items="payment_states" item-text="name" item-value="id"
            :disabled="disabled">
          </v-select>
        </v-col>

        <v-col v-if="
          FinancialDashboardFilter ||
          OrdersFilter ||
          InvoicesFilter ||
          OrdersReportsFilter ||
          ExpirationFilter ||
          OrdersStatisticsFilter ||
          TicketsFilter
        ">
          <v-autocomplete :disabled="loadingFilters" clearable dense no-filter v-model="flt_customer"
            :items="flt_customers" :loading="isLoadingCustomer" :search-input.sync="searchCustomerInput" outlined
            item-text="display_name" item-value="id" :label="$t('filters-customers')" return-object />
        </v-col>

        <v-col v-if="OrdersFilter">
          <v-select class="minfield" outlined dense :label="$t('filters-delivery-point')" ref="period"
            v-model="filters.filterByDeliveryPoint" :items="delivery_points" item-text="address" item-value="id"
            :disabled="!flt_customer">
          </v-select>
        </v-col>

        <v-col v-if="
          (OrdersReportsFilter ||
            OrdersFilter ||
            FinancialDashboardFilter ||
            VehiclesHistoryFilter) &&
          !CourierFilter
        ">
          <v-autocomplete :disabled="loadingFilters" clearable dense no-filter v-model="flt_courier"
            :items="flt_couriers" :loading="isLoadingCourier" :search-input.sync="searchCourierInput" outlined
            item-text="name" item-value="id" :label="$t('filters-courier')" return-object />
        </v-col>

        <v-col v-if="VehiclesHistoryFilter">
          <v-autocomplete :disabled="loadingFilters" clearable dense no-filter v-model="flt_plate" :items="flt_plates"
            :loading="isLoadingPlate" :search-input.sync="searchPlateInput" outlined item-text="name" item-value="id"
            :label="$t('filters-plate')" return-object />
        </v-col>

        <v-col v-if="
          VehiclesHistoryFilter ||
          FinancialDashboardFilter ||
          (OrdersFilter && !CourierFilter)
        ">
          <v-autocomplete :disabled="loadingFilters" clearable dense no-filter v-model="flt_user" :items="flt_users"
            :loading="isLoadingUser" :search-input.sync="searchUserInput" outlined item-text="name" item-value="id"
            :label="$t('filters-users')" return-object />
        </v-col>
        <v-col v-if="
          FinancialDashboardFilter ||
          OrdersFilter ||
          InvoicesFilter ||
          OrdersReportsFilter ||
          ExpirationFilter ||
          OrdersStatisticsFilter
        ">
          <v-select class="minfield" outlined dense :label="$t('filters-sales-point')"
            v-model="filters.filterBySalesPoint" :items="salesPoints" item-text="name" item-value="id"
            :disabled="disabled">
          </v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-if="FinancialDashboardFilter || ExpirationFilter">
          <v-select class="minfield" outlined dense :label="$t('filters-customer-category')"
            v-model="filters.filterByCustomerCategory" :items="customerCategories" item-text="name" item-value="id"
            :disabled="disabled">
          </v-select>
        </v-col>

        <v-col v-if="
          FinancialDashboardFilter ||
          ExpirationFilter ||
          OrdersStatisticsFilter
        ">
          <v-select class="minfield" outlined dense :label="$t('filters-product-category')"
            v-model="filters.filterByProductCategory" :items="productCategories" item-text="name" item-value="id"
            :disabled="disabled">
          </v-select>
        </v-col>

        <v-col v-if="FinancialDashboardFilter || OrdersStatisticsFilter">
          <v-autocomplete :disabled="loadingFilters" clearable dense no-filter v-model="flt_product"
            :items="flt_products" :loading="isLoadingProducts" :search-input.sync="searchProductInput" outlined
            item-text="name" item-value="id" :label="$t('filters-product')" return-object></v-autocomplete>
        </v-col>

        <v-col col="1" v-if="(InvoicesFilter || OrdersFilter) && !hideInvoicesFilters">
          <v-text-field class="minfield" outlined dense :label="$t('filters-period-invoice-number')"
            :value="filters.filterByInvoiceNumbers" @change="value => (filters.filterByInvoiceNumbers = value)"
            :disabled="disabled">
          </v-text-field>
        </v-col>
        <v-col col="1" v-if="(InvoicesFilter || OrdersFilter) && !hideInvoicesFilters">
          <v-text-field class="minfield" outlined dense :label="$t('filters-period-invoice-year')"
            :value="filters.filterByInvoiceYear" @change="value => (filters.filterByInvoiceYear = value)"
            :disabled="disabled">
          </v-text-field>
        </v-col>

        <v-col col="1" v-if="InvoicesFilter || OrdersFilter">
          <v-text-field class="minfield" outlined dense :label="$t('filters-period-ddt-number')"
            :value="filters.filterByDDTNumbers" @change="value => (filters.filterByDDTNumbers = value)"
            :disabled="disabled">
          </v-text-field>
        </v-col>
        <v-col col="1" v-if="InvoicesFilter || OrdersFilter">
          <v-text-field class="minfield" outlined dense :label="$t('filters-period-ddt-year')"
            :value="filters.filterByDDTYear" @change="value => (filters.filterByDDTYear = value)" :disabled="disabled">
          </v-text-field>
        </v-col>

        <v-col v-if="
          VehiclesHistoryFilter ||
          FinancialDashboardFilter ||
          OrdersFilter ||
          InvoicesFilter ||
          OrdersReportsFilter ||
          OrdersStatisticsFilter ||
          TicketsFilter
        ">
          <v-select class="minfield" outlined dense :label="$t('filters-period-hint')" ref="period"
            v-model="filters.filterByPeriod" :items="getPeriodFilters" item-text="name" item-value="id"
            :disabled="disabled">
          </v-select>
        </v-col>
        <v-col v-if="
          (FinancialDashboardFilter ||
            VehiclesHistoryFilter ||
            OrdersFilter ||
            InvoicesFilter ||
            OrdersStatisticsFilter ||
            TicketsFilter ||
            OrdersReportsFilter) &&
          filters.filterByPeriod == 13
        ">
          <v-dialog ref="dialog_start_date" v-model="date_start_modal"
            :return-value.sync="filters.filterByCustomDateStart" width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field dense outlined v-model="start_date_formatted" :disabled="disabled"
                :label="$t('filters-startdate-hint')" readonly clearable v-on="on"></v-text-field>
            </template>
            <v-date-picker @input="
              $refs.dialog_start_date.save(filters.filterByCustomDateStart)
              " v-model="filters.filterByCustomDateStart" scrollable :first-day-of-week="1"
              :locale="$store.state.user.locale">
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col v-if="
          (FinancialDashboardFilter ||
            VehiclesHistoryFilter ||
            OrdersFilter ||
            InvoicesFilter ||
            TicketsFilter ||
            OrdersStatisticsFilter ||
            OrdersReportsFilter) &&
          filters.filterByPeriod == 13
        ">
          <v-dialog ref="dialog_end_date" v-model="date_end_modal" :return-value.sync="filters.filterByCustomDateEnd"
            width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field dense outlined v-model="end_date_formatted" :disabled="disabled"
                :label="$t('filters-enddate-hint')" readonly clearable v-on="on"></v-text-field>
            </template>
            <v-date-picker @input="$refs.dialog_end_date.save(filters.filterByCustomDateEnd)"
              v-model="filters.filterByCustomDateEnd" scrollable :first-day-of-week="1"
              :locale="$store.state.user.locale">
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.minfield {
  min-width: 100px;
}
</style>

<script>
import staticData from "@/apis/static";
import customersManager from "@/apis/customers";
import usersManager from "@/apis/users";
import productsManager from "@/apis/products";
import vehicleManager from "@/apis/vehicles";
import config from "@/config";

export default {
  data() {
    return {
      loadingFilters: false,
      date_start_modal: false,
      date_end_modal: false,
      filtersChanged: false,
      document_types: [
        {
          name: this.$t("gbl-all-male"),
          id: null
        },
        {
          name: this.$t("filters-doc-type-invoice"),
          id: 1
        },
        {
          name: this.$t("filters-doc-type-nc"),
          id: 2
        }
      ],
      orders_states: [
        {
          name: this.$t("gbl-all-male"),
          id: null
        },
        ...staticData.ordersStates
      ],

      tickets_states: [
        {
          name: this.$t("gbl-all-male"),
          id: null
        },
        { name: this.$t("ticket-state-close"), id: 2 },
        { name: this.$t("ticket-state-open"), id: 3 }
      ],

      orders_sources: [
        {
          name: this.$t("gbl-all-male"),
          id: null
        },
        {
          name: this.$t("gbl-all-offline"),
          id: false
        },
        {
          name: this.$t("gbl-all-online"),
          id: true
        }
      ],
      payment_states: [
        {
          name: this.$t("gbl-all-male"),
          id: null
        },
        ...staticData.invoicesPaymentsStates
      ],
      flt_customer: null,
      flt_customers: [],
      searchCustomerInput: null,
      isLoadingCustomer: false,

      flt_product: null,
      flt_products: [],
      searchProductInput: null,
      isLoadingProducts: false,

      flt_user: null,
      flt_users: [],
      searchUserInput: null,
      isLoadingUser: false,

      flt_courier: null,
      flt_couriers: [],

      flt_plate: null,
      flt_plates: [],

      salesPoints: [],
      customerCategories: [],
      productCategories: [],
      searchCourierInput: null,
      searchPlateInput: null,

      isLoadingCourier: false,
      isLoadingPlate: false,

      delivery_points: []
    };
  },

  mounted() {
    this.fetchFilters();
    this.salesPoints = [
      { id: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.sales_points
    ];
    if (this.LoanOrders) {
      this.orders_states = [
        ...this.orders_states,
        { name: this.$t("order-state-expired"), id: 4 }
      ];
    }
  },

  computed: {
    start_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      }
    },

    end_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      }
    },

    getPeriodFilters: {
      get: function () {
        return staticData.statisticsPeriods;
      }
    }
  },

  methods: {
    onFiltersLoading: function () {
      this.$emit("onFiltersLoading");
    },

    onFiltersLoaded: function () {
      this.$emit("onFiltersLoaded");
    },

    fetchFilters: function () {
      this.filtersChanged = true;
      this.loadingFilters = true;
      this.onFiltersLoading();
      this.fetchProductCategories().then(res => {
        this.productCategories = [
          { id: null, name: this.$t("gbl-all-female") },
          ...res.items
        ];

        this.fetchCustomerCategories().then(res => {
          this.customerCategories = [
            { id: null, name: this.$t("gbl-all-female") },
            ...res.items
          ];

          this.onFiltersLoaded();
          this.loadingFilters = false;
        });
      });
    },

    fetchProductCategories() {
      return new Promise((resolve, reject) => {
        productsManager
          .fetchCategories()
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchCustomerCategories() {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchCategories()
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchDeliveryPoints() {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchDeliveryPoint(this.flt_customer.id)
          .then(results => {
            this.delivery_points = [
              { id: null, address: this.$t("gbl-all-male") },
              ...results.items
            ];
            this.filters.filterByDeliveryPoint = null;
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterProducts(filter) {
      return new Promise((resolve, reject) => {
        productsManager
          .fetchFilterProducts(filter, this.filters.filterByProductCategory)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterUser(filter) {
      return new Promise((resolve, reject) => {
        usersManager
          .fetchUsersNames(filter)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterCourier(filter) {
      return new Promise((resolve, reject) => {
        usersManager
          .fetchCouriersNames(filter)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterVehicles(filter) {
      return new Promise((resolve, reject) => {
        vehicleManager
          .fetchFilterVehicles(filter)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    }
  },

  watch: {
    searchPlateInput(val) {
      clearTimeout(this._searchProductInput);
      if (val) {
        this._searchProductInput = setTimeout(() => {
          this.isLoadingPlate = true;
          this.fetchFilterVehicles(val)
            .then(res => {
              this.flt_plates = res.items;
              this.isLoadingPlate = false;
            })
            .catch(() => {
              this.isLoadingPlate = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.filters.filterByVehicleId = null;
      }
    },

    searchProductInput(val) {
      clearTimeout(this._searchProductInput);
      if (val) {
        this._searchProductInput = setTimeout(() => {
          this.isLoadingProducts = true;
          this.fetchFilterProducts(val, this.filters.filterByProductCategory)
            .then(res => {
              this.flt_products = res.items;
              this.isLoadingProducts = false;
            })
            .catch(() => {
              this.isLoadingProducts = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.filters.filterByProductId = null;
      }
    },

    searchCustomerInput(val) {
      clearTimeout(this._searchCustomerInput);
      if (val && !this.flt_customers.some(item => item.display_name === val)) {
        this._searchCustomerInput = setTimeout(() => {
          this.isLoadingCustomer = true;

          this.fetchFilterCustomers(val)
            .then(res => {
              this.flt_customers = res.customers.map(x => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomer = false;
            })
            .catch(() => {
              this.isLoadingCustomer = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!val) this.filters.filterByCustomerId = null;
      }
    },

    searchUserInput(val) {
      clearTimeout(this._searchUserInput);
      if (val && !this.flt_users.some(item => item.name === val)) {
        this._searchUserInput = setTimeout(() => {
          this.isLoadingUser = true;
          this.fetchFilterUser(val)
            .then(res => {
              this.flt_users = res.items.map(x => {
                x.name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingUser = false;
            })
            .catch(() => {
              this.isLoadingUser = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!val) this.filters.filterByUserId = null;
      }
    },

    searchCourierInput(val) {
      clearTimeout(this._searchCourierInput);
      if (val && !this.flt_couriers.some(item => item.name === val)) {
        this._searchCourierInput = setTimeout(() => {
          this.isLoadingCourier = true;
          this.fetchFilterCourier(val)
            .then(res => {
              this.flt_couriers = res.items.map(x => {
                x.name = x.first_name + " " + x.last_name;
                return x;
              });

              this.isLoadingCourier = false;
            })
            .catch(() => {
              this.isLoadingCourier = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!val) this.filters.filterByCourierId = null;
      }
    },

    flt_plate: {
      handler() {
        this.filters.filterByVehicleId = this.flt_plate
          ? this.flt_plate.id
          : null;
      }
    },

    flt_product: {
      handler() {
        this.filters.filterByProductId = this.flt_product
          ? this.flt_product.id
          : null;
      }
    },

    flt_customer: {
      handler() {
        this.filters.filterByCustomerId = this.flt_customer
          ? this.flt_customer.id
          : null;
        if (this.flt_customer && this.flt_customer.id)
          this.fetchDeliveryPoints();
        else {
          this.filters.filterByDeliveryPoint = null;
          this.delivery_points = [];
        }
      }
    },

    flt_user: {
      handler() {
        this.filters.filterByUserId = this.flt_user ? this.flt_user.id : null;
      }
    },

    flt_courier: {
      handler() {
        this.filters.filterByCourierId = this.flt_courier
          ? this.flt_courier.id
          : null;
      }
    },

    "filters.filterByInvoiceState": {
      handler() {
        if (!this.filtersChanged) {
          this.filtersChanged = true;

          this.filtersChanged = false;
        }
      }
    },
    "filters.filterByPeriod": {
      handler() {
        if (!this.filtersChanged) {
          this.filtersChanged = true;

          this.filtersChanged = false;
        }
      }
    },

    "filters.filterByCustomDateStart": {
      handler() {
        if (!this.filtersChanged) {
          this.filtersChanged = true;

          this.filtersChanged = false;
        }
      }
    },

    "filters.filterByCustomDateEnd": {
      handler() {
        if (!this.filtersChanged) {
          this.filtersChanged = true;

          this.filtersChanged = false;
        }
      }
    }
  },
  props: [
    "disabled",
    "filters",
    "showLoader",
    "FinancialDashboardFilter",
    "OrdersFilter",
    "TicketsFilter",
    "LoanOrders",
    "InvoicesFilter",
    "OrdersReportsFilter",
    "CourierFilter",
    "VehiclesHistoryFilter",
    "ExpirationFilter",
    "hideInvoicesFilters",
    "OrdersStatisticsFilter"
  ]
};
</script>

<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <messageBox ref="msgBox" />
        <setString ref="setString" />
        <creditNoteEditor
          ref="creditNoteEditor"
          @createNC="handleCN"
          v-model="creditNoteEditorDialog"
        />

        <v-dialog
          overlay-opacity="0.6"
          max-width="600"
          :value="showSignatureDialog"
          persistent
        >
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"
                        ><v-icon large>mdi-account</v-icon></v-col
                      >
                      <v-col
                        align="center"
                        cols="10"
                        class="pa-0 card-title-text"
                        style="font-size: 25px"
                        >{{ $t("order-show-signature-title") }}</v-col
                      >
                      <v-col cols="1" class="pa-0"
                        ><v-icon @click="showSignatureDialog = false" large
                          >mdi-close</v-icon
                        ></v-col
                      >
                    </v-row>
                  </template>
                  <div style="height:10px" />
                  <img :src="signatureData" />
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <v-dialog
          overlay-opacity="0.6"
          max-width="600"
          :value="changeInvoicePaymentStateDialog"
          persistent
        >
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"
                        ><v-icon large
                          >mdi-office-building-marker-outline</v-icon
                        ></v-col
                      >
                      <v-col
                        align="center"
                        cols="10"
                        class="pa-0 card-title-text"
                        style="font-size: 25px"
                        >{{ $t("payment-editor-title-modify") }}</v-col
                      >
                      <v-col cols="1" class="pa-0"
                        ><v-icon
                          @click="changeInvoicePaymentStateDialog = false"
                          large
                          >mdi-close</v-icon
                        ></v-col
                      >
                    </v-row>
                  </template>
                  <div style="height:10px" />

                  <v-select
                    :label="$t('payment-editor-hint-type')"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    v-model="invoicePaymentStateDialogValue"
                    :items="getPaymentStates"
                  />
                  <v-btn
                    @click="handleChangePaymentInvoiceState()"
                    color="primary"
                    >{{ $t("profile-change-pwd-confirm") }}</v-btn
                  >
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success darken-1"
              icon="mdi-currency-eur"
              :title="$t('invoicesreports-total-net-value')"
              :value="computeNetValue"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success darken-2"
              icon="mdi-currency-eur"
              :title="$t('invoicesreports-total-gross-value')"
              :value="computeGrossValue"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success"
              icon="mdi-currency-eur"
              :title="$t('invoicesreports-total-payed')"
              :value="computeTotalPayed"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="warning"
              icon="mdi-currency-eur"
              :title="$t('invoicesreports-total-not-payed')"
              :value="computeTotalUnpayed"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
        </v-row>

        <div class="vertical-spacer" />
        <FiltersBar
          :filters="filters"
          :disabled="loadingInvoices"
          @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded"
          :showLoader="true"
          :InvoicesFilter="true"
        />

        <v-btn
          :disabled="
            exporting_invoices ||
              !invoices ||
              invoices.length < 1 ||
              !$store.state.spooler_online
          "
          @click="batchPrint()"
          color="secondary"
          >{{ $t("invoicesmng-batch-print") }}</v-btn
        >

        <v-btn
          :disabled="exporting_invoices || !invoices || invoices.length < 1"
          @click="downloadReportXMLZip()"
          color="primary"
          >{{ $t("invoicesmng-download-xml-zip") }}</v-btn
        >

        <v-btn
          :disabled="exporting_invoices || !invoices || invoices.length < 1"
          @click="downloadReportExcel()"
          color="green"
          >{{ $t("invoicesmng-download-excel") }}
          <v-icon right dark>mdi-file-excel-outline</v-icon></v-btn
        >

        <base-material-card
          v-if="!loadingFilters"
          color="primary"
          icon="mdi-cube-send"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("invoicesreports-title") }}
            </div>
          </template>

          <div style="height:10px" />

          <v-text-field
            v-model="filter"
            append-icon="mdi-magnify"
            class="mr-auto pl-1"
            :label="$t('gbl-search')"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <v-tooltip
            v-if="
              $store.state.hasRight('sa') &&
                filters.filterByInvoiceNumbers &&
                filters.filterByInvoiceYear
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                large
                text
                icon
                color="red"
                @click="
                  rebuildRange(
                    filters.filterByInvoiceNumbers,
                    filters.filterByInvoiceYear
                  )
                "
                v-on="on"
                ><v-icon>mdi-refresh</v-icon></v-btn
              ></template
            ><span>{{ $t("ordermng-rebuild-document") }}</span></v-tooltip
          >

          <div class="vertical-spacer" />

          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="invoices"
            :options.sync="options"
            :server-items-length="totalInvoices"
            :loading="loadingInvoices"
            :footer-props="dataTableFooterOptions"
            v-model="selectedInvoices"
            item-key="row_id"
            show-select
          >
            <template color="green" v-slot:item.signature_id="{ item }">
              <v-tooltip v-if="item.signature_id" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="green darken-2"
                    @click="showSignature(item.doc_number, item.doc_year)"
                    v-on="on"
                    ><v-icon>mdi-check-circle-outline</v-icon></v-btn
                  ></template
                ><span>{{ $t("ordermng-show-signature") }}</span></v-tooltip
              >
            </template>

            <template v-slot:item.document_date="{ item }">
              {{ item.document_date | toLocaleDate }}
            </template>

            <template v-slot:item.doc_number="{ item }">
              <div style="min-width:180px">
                <v-icon color="green" v-if="!item.nc">mdi-circle</v-icon>
                <v-icon color="purple" v-if="item.nc">mdi-circle</v-icon>
                {{ item.doc_number }} - {{ item.doc_year }}
                <v-tooltip v-if="item.nc" bottom
                  ><template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="cyan"
                      >mdi-information-outline</v-icon
                    > </template
                  ><span
                    >{{ $t("ordermng-ref-invoice-nc")
                    }}{{ item.ref_doc_number }} - {{ item.ref_doc_year }}</span
                  ></v-tooltip
                >

                <v-tooltip v-if="!item.nc && item.ref_nc" bottom
                  ><template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="cyan"
                      >mdi-information-outline</v-icon
                    > </template
                  ><span
                    >{{ $t("ordermng-ref-nc") }}{{ item.ref_cn_doc_number }} -
                    {{ item.ref_cn_doc_year }}</span
                  ></v-tooltip
                >

                <v-tooltip
                  v-if="
                    $store.state.hasRight('196') &&
                      !item.nc &&
                      !$store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="printInvoice(item)"
                      v-on="on"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-print-invoice") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="
                    $store.state.hasRight('196') &&
                      !item.nc &&
                      $store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="printInvoice(item, true)"
                      v-on="on"
                      ><v-icon>mdi-printer-wireless</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-print-invoice") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="$store.state.hasRight('196') && !item.nc"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="downloadInvoice(item)"
                      v-on="on"
                      ><v-icon>mdi-file-check-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-download-invoice") }}</span></v-tooltip
                >
                <v-tooltip
                  v-if="$store.state.hasRight('196') && !item.nc"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green darken-4"
                      @click="downloadInvoiceDetail(item)"
                      v-on="on"
                      ><v-icon>mdi-file-search-outline</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("ordermng-download-invoice-detail")
                  }}</span></v-tooltip
                >
                <v-tooltip
                  v-if="$store.state.hasRight('196') && !item.nc"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="downloadInvoiceXml(item)"
                      v-on="on"
                      ><v-icon>mdi-xml</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("ordermng-download-invoice-xml")
                  }}</span></v-tooltip
                >
                <v-tooltip
                  v-if="
                    $store.state.hasRight('202') &&
                      !item.ref_doc_number &&
                      !item.nc
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      :disabled="item.ref_nc"
                      x-small
                      text
                      icon
                      color="red"
                      @click="createTotalCreditNote(item)"
                      v-on="on"
                      ><v-icon>mdi-file-undo-outline</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("ordermng-create-credit-note")
                  }}</span></v-tooltip
                >
                <v-tooltip
                  v-if="
                    $store.state.hasRight('202') &&
                      !item.ref_doc_number &&
                      !item.nc
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      :disabled="item.ref_nc"
                      x-small
                      text
                      icon
                      color="orange"
                      @click="createPartialCreditNote(item)"
                      v-on="on"
                      ><v-icon>mdi-file-undo-outline</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("ordermng-create-partial-credit-note")
                  }}</span></v-tooltip
                >
                <v-tooltip
                  v-if="
                    $store.state.hasRight('196') &&
                      item.ref_doc_number &&
                      !item.nc &&
                      !$store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="purple"
                      @click="printCN(item, false)"
                      v-on="on"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-print-cn") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="
                    $store.state.hasRight('196') &&
                      item.ref_doc_number &&
                      !item.nc &&
                      $store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="purple"
                      @click="printCN(item, false, true)"
                      v-on="on"
                      ><v-icon>mdi-printer-wireless</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-print-cn") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="
                    $store.state.hasRight('196') &&
                      item.ref_doc_number &&
                      !item.nc
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="purple"
                      @click="downloadCN(item, false)"
                      v-on="on"
                      ><v-icon>mdi-file-check-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-download-cn") }}</span></v-tooltip
                >
                <v-tooltip
                  v-if="
                    $store.state.hasRight('196') &&
                      item.ref_doc_number &&
                      !item.nc
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="purple"
                      @click="downloadCNXml(item, false)"
                      v-on="on"
                      ><v-icon>mdi-xml</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-download-cn-xml") }}</span></v-tooltip
                >
                <v-tooltip
                  v-if="
                    $store.state.hasRight('196') &&
                      item.nc &&
                      !$store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="purple"
                      @click="printCN(item, true)"
                      v-on="on"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-print-cn") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="
                    $store.state.hasRight('196') &&
                      item.nc &&
                      $store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="purple"
                      @click="printCN(item, true, true)"
                      v-on="on"
                      ><v-icon>mdi-printer-wireless</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-print-cn") }}</span></v-tooltip
                >

                <v-tooltip v-if="$store.state.hasRight('196') && item.nc" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="purple"
                      @click="downloadCN(item, true)"
                      v-on="on"
                      ><v-icon>mdi-file-check-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-download-cn") }}</span></v-tooltip
                >
                <v-tooltip v-if="$store.state.hasRight('196') && item.nc" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="purple"
                      @click="downloadCNXml(item, true)"
                      v-on="on"
                      ><v-icon>mdi-xml</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-download-cn-xml") }}</span></v-tooltip
                >

                <v-tooltip v-if="$store.state.hasRight('sa')" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="red"
                      @click="rebuildDocument(item)"
                      v-on="on"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-rebuild-document") }}</span></v-tooltip
                >
              </div>
            </template>
            <template v-slot:item.business_name="{ item }">
              <v-icon :color="item.reference_color">mdi-circle</v-icon>
              {{
                item.business_name
                  ? item.business_name
                  : item.first_name + " " + item.last_name
              }}
            </template>

            <template v-slot:item.total_net="{ item }">
              {{
                item.total_net
                  ? item.nc
                    ? $options.filters.toCurrency(-item.total_net.toFixed(2))
                    : $options.filters.toCurrency(item.total_net.toFixed(2))
                  : ""
              }}
            </template>

            <template v-slot:item.total_vat="{ item }">
              {{
                item.total_vat
                  ? item.nc
                    ? $options.filters.toCurrency(-item.total_vat.toFixed(2))
                    : $options.filters.toCurrency(item.total_vat.toFixed(2))
                  : ""
              }}
            </template>

            <template v-slot:item.total_gross="{ item }">
              {{
                item.total_gross
                  ? item.nc
                    ? $options.filters.toCurrency(-item.total_gross.toFixed(2))
                    : $options.filters.toCurrency(item.total_gross.toFixed(2))
                  : ""
              }}
            </template>

            <template v-slot:item.payment_state="{ item }">
              <span v-if="!item.nc">{{ getPaymentState(item) }}</span>
              <v-tooltip v-if="$store.state.hasRight('182') && !item.nc" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="orange darken-2"
                    @click="setInvoicePaymentState(item)"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>{{ $t("ordermng-change-state") }}</span></v-tooltip
              >
            </template>

            <template v-slot:item.num_orders="{ item }">
              <span v-if="!item.nc">{{ getNumOrders(item.orders_id) }}</span>
            </template>
          </v-data-table>

          <v-btn
            v-if="$store.state.hasRight('190')"
            :disabled="selectedInvoices.length < 1"
            @click="setSelectedAsPayed(4)"
            color="primary"
            >{{ $t("invoicesmng-set-selection-payed-wire") }}
          </v-btn>
          <v-btn
            v-if="$store.state.hasRight('190')"
            :disabled="selectedInvoices.length < 1"
            @click="setSelectedAsPayed(3)"
            color="primary"
            >{{ $t("invoicesmng-set-selection-payed-check") }}
          </v-btn>
          <v-btn
            v-if="$store.state.hasRight('190')"
            :disabled="selectedInvoices.length < 1"
            @click="setSelectedAsPayed(2)"
            color="primary"
            >{{ $t("invoicesmng-set-selection-payed-cash") }}
          </v-btn>
          <v-btn
            v-if="$store.state.hasRight('190')"
            :disabled="selectedInvoices.length < 1"
            @click="setSelectedAsNotPayed()"
            color="primary"
            >{{ $t("invoicesmng-set-selection-not-payed") }}
          </v-btn>

          <v-btn
            v-if="$store.state.hasRight('190')"
            :disabled="selectedInvoices.length < 1"
            @click="setSelectedAsVoid()"
            color="primary"
            >{{ $t("invoicesmng-set-selection-void") }}
          </v-btn>
          <v-btn
            v-if="$store.state.hasRight('190')"
            :disabled="selectedInvoices.length < 1"
            @click="setSelectedAsCompleteLoss()"
            color="primary"
            >{{ $t("invoicesmng-set-selection-completed-loss") }}
          </v-btn>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import ordersManager from "../apis/orders";
import usersManager from "../apis/users";

import creditNoteEditor from "../components/CreditNoteEditor";
import setString from "../components/SetString";
import FiltersBar from "../components/FiltersBar";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      changeInvoicePaymentStateDialog: false,
      invoicePaymentStateDialogItem: null,
      invoicePaymentStateDialogValue: null,
      selectedInvoices: [],

      paymentStates: staticData.ordersPaymentStates,
      invoicesPaymentsStates: staticData.invoicesPaymentsStates,

      options: {
        sortBy: ["doc_number"],
        sortDesc: [true]
      },

      headers: [],
      perPage: staticData.defautPerPage,
      totalOrders: 0,
      filter: "",
      fetching_data: false,

      filters: {
        filterByUserId: null,
        filterByCustomerId: null,
        filterByPeriod: 8,
        filterBySalesPoint: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByOrderState: null,
        filterByInvoiceState: null,
        filterByInvoiceNumbers: null,
        filterByInvoiceYear: null,
        filterByDDTNumbers: null,
        filterByDDTYear: null,
        filterByDocumentType: null,
        filterByText: null
      },
      invoices: [],
      totalInvoices: 0,
      loadingInvoices: false,
      loadingFilters: true,
      exporting_invoices: false,
      stats: null,
      payed: null,
      unpayed: null,
      building_invoices: false,
      creditNoteEditorDialog: false,
      signatureData: null,
      showSignatureDialog: false
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("invoicesreports-tbl-customer"),
        align: "left",
        sortable: true,
        value: "business_name"
      },

      {
        text: this.$t("invoicesreports-tbl-date"),
        align: "left",
        sortable: true,
        value: "document_date"
      },

      {
        text: this.$t("invoicesreports-tbl-docnumber"),
        align: "left",
        sortable: true,
        value: "doc_number"
      },
      {
        text: this.$t("invoicesreports-tbl-signature"),
        align: "center",
        sortable: true,
        value: "signature_id"
      },

      {
        text: this.$t("invoicesreports-tbl-gross"),
        align: "left",
        sortable: true,
        value: "total_net"
      },

      {
        text: this.$t("invoicesreports-tbl-vat"),
        align: "left",
        sortable: true,
        value: "total_vat"
      },
      {
        text: this.$t("invoicesreports-tbl-total"),
        align: "left",
        sortable: true,
        value: "total_gross"
      },

      {
        text: this.$t("invoicesreports-tbl-amount"),
        align: "left",
        sortable: true,
        value: "num_orders"
      },

      {
        text: this.$t("invoicesreports-payment-state"),
        align: "left",
        sortable: true,
        value: "payment_state"
      }
    ];
  },

  components: {
    messageBox,
    FiltersBar,
    setString,
    creditNoteEditor
  },

  watch: {
    options: {
      handler() {
        this.fetchInvoices();
      },
      deep: true
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchInvoices();
        }, 300);
      }
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchInvoices();
        }, 500);
      },
      deep: true
    }
  },

  computed: {
    getPaymentStates() {
      let ar = [...this.paymentStates].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return ar;
    },
    filterByPeriodText() {
      return staticData.statisticsPeriods[this.filters.filterByPeriod].name;
    },

    computeGrossValue() {
      if (this.stats)
        return this.$options.filters.toCurrencySymbol(
          this.stats.net_total + this.stats.vat_total
        );
      else return "...";
    },

    computeNetValue() {
      if (this.stats) {
        return this.$options.filters.toCurrencySymbol(this.stats.net_total);
      } else return "...";
    },

    computeTotalPayed() {
      if (this.payed) {
        return this.$options.filters.toCurrencySymbol(this.payed.gross_total);
      } else return "...";
    },

    computeTotalUnpayed() {
      if (this.unpayed) {
        return this.$options.filters.toCurrencySymbol(this.unpayed.gross_total);
      } else return "...";
    }
  },

  methods: {
    showSignature(num, year) {
      ordersManager
        .fetchInvoiceSignature(num, year)
        .then(res => {
          this.signatureData = res;
          this.showSignatureDialog = true;
        })
        .catch(err => {
          console.log(err);
        });
    },

    setInvoicePaymentState(item) {
      this.changeInvoicePaymentStateDialog = true;
      this.invoicePaymentStateDialogValue = this.getPaymentStateId(
        item.payments_states
      );
      this.invoicePaymentStateDialogItem = item;
    },

    handleChangePaymentInvoiceState() {
      ordersManager
        .changeInvoicePaymentStatus(
          this.invoicePaymentStateDialogItem.orders_id,
          this.invoicePaymentStateDialogValue
        )
        .then(() => {
          this.invoicePaymentStateDialogItem.payments_states = this.invoicePaymentStateDialogValue.toString();
          this.changeInvoicePaymentStateDialog = false;
          this.invoicePaymentStateDialogItem = null;
          this.invoicePaymentStateDialogValue = null;
          this.fetchInvoices();
        })
        .catch(err => {
          console.log(err);
          this.changeInvoicePaymentStateDialog = false;
          this.invoicePaymentStateDialogItem = null;
          this.invoicePaymentStateDialogValue = null;
        });
    },

    getNumOrders(orders) {
      return orders.split(",").length;
    },

    getPaymentState(item) {
      const states = item.payments_states;
      let state = 0; // Not payed
      let s = states.split(",");
      let somethingPayed = false;
      let somethingUnpayed = false;
      let somethingVoid = false;
      let somethingVoid2 = false;
      for (let n = 0; n < s.length; n++) {
        if (parseInt(s[n]) > 1) somethingPayed = true;
        if (parseInt(s[n]) < 2) somethingUnpayed = true;
        if (parseInt(s[n]) == 5) somethingVoid = true;
        if (parseInt(s[n]) == 8) somethingVoid2 = true;
      }

      if (somethingUnpayed && somethingPayed) state = 1;
      else if (somethingUnpayed) state = 0;
      else if (somethingVoid) state = 3;
      else if (somethingVoid2) state = 4;
      else state = 2;

      if (parseInt(item.partial_payment) == 1) state = 1;
      return staticData.invoicesPaymentsStates[state].name;
    },

    getPaymentStateId(states) {
      let state = 0;
      let s = states.split(",");
      for (let n = 0; n < s.length; n++) {
        if (s[n] > state) state = s[n];
      }

      return staticData.ordersPaymentStates[state].id;
    },

    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    fetchInvoices() {
      this.loadingInvoices = true;
      this.totalInvoices = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByInvoiceState = this.filters.filterByInvoiceState;
      ctx.filterByInvoiceNumbers = this.filters.filterByInvoiceNumbers;
      ctx.filterByInvoiceYear = this.filters.filterByInvoiceYear;
      ctx.filterByDDTNumbers = this.filters.filterByDDTNumbers;
      ctx.filterByDDTYear = this.filters.filterByDDTYear;
      ctx.filterByDocumentType = this.filters.filterByDocumentType;
      ctx.filterBySalesPoint = this.filters.filterBySalesPoint;
      ctx.filterByText = this.filters.filterByText;

      ordersManager
        .fetchInvoices(ctx)
        .then(result => {
          this.stats = result.stats;
          this.payed = result.payed;
          this.unpayed = result.unpayed;
          if (!this.payed.gross_total) this.payed.gross_total = 0.0;
          if (!this.unpayed.gross_total) this.unpayed.gross_total = 0.0;

          if (!this.stats.net_total) this.stats.net_total = 0.0;
          if (!this.stats.vat_total) this.stats.vat_total = 0.0;

          this.paymentStats = result.paymentStats;
          this.totalInvoices = result.totalCount;
          this.invoices = result.items;
          this.loadingInvoices = false;
        })
        .catch(err => {
          this.loadingInvoices = false;
          console.log(err);
        });
    },

    createTotalCreditNote(item) {
      this.$refs.setString
        .show(
          this.$t("invoice-create-total-nc-title"),
          this.$t("invoice-create-total-nc-hint"),
          this.$t("invoice-create-total-nc-text"),
          null,
          item,
          false,
          false
        )
        .then(event => {
          this.fetching_data = true;
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "invoicesmng-work-in-progress"
          );

          ordersManager
            .createIntegralCreditNote(
              item.doc_number,
              item.doc_year,
              event.value
            )
            .then(() => {
              this.fetching_data = false;
              this.$store.state.global_overlay = false;
              this.fetchInvoices();
            })
            .catch(err => {
              console.log(err);
              this.fetching_data = false;
              this.$store.state.global_overlay = false;
            });
        })
        .catch(() => {});
    },

    createPartialCreditNote(item) {
      this.fetching_data = true;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "invoicesmng-work-in-progress"
      );
      ordersManager
        .fetchOrderForCN(item.doc_number, item.doc_year)
        .then(order => {
          this.fetching_data = false;
          this.$store.state.global_overlay = false;
          this.$refs.creditNoteEditor.setModalCNOrder(order);
          this.creditNoteEditorDialog = true;
        })
        .catch(err => {
          console.log(err);
          this.fetching_data = false;
          this.$store.state.global_overlay = false;
        });
    },

    handleCN(event) {
      let order = event.order;
      this.creditNoteEditorDialog = false;
      this.fetching_data = true;
      this.$store.state.global_overlay_text = this.$t(
        "invoicesmng-work-in-progress"
      );
      this.$store.state.global_overlay = true;
      ordersManager
        .createPartialCreditNote(order)
        .then(() => {
          this.fetching_data = false;
          this.$store.state.global_overlay = false;
          this.fetchInvoices();
        })
        .catch(err => {
          console.log(err);
          this.fetching_data = false;
          this.$store.state.global_overlay = false;
        });
    },

    downloadReportExcel() {
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByInvoiceState = this.filters.filterByInvoiceState;
      ctx.filterByInvoiceNumbers = this.filters.filterByInvoiceNumbers;
      ctx.filterByInvoiceYear = this.filters.filterByInvoiceYear;
      ctx.filterByDDTNumbers = this.filters.filterByDDTNumbers;
      ctx.filterByDDTYear = this.filters.filterByDDTYear;
      ctx.filterByDocumentType = this.filters.filterByDocumentType;
      ctx.filterByText = this.filters.filterByText;
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadInvoicesExcel(ctx)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    batchPrint() {
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByInvoiceState = this.filters.filterByInvoiceState;
      ctx.filterByInvoiceNumbers = this.filters.filterByInvoiceNumbers;
      ctx.filterByDDTNumbers = this.filters.filterByDDTNumbers;
      ctx.filterByDDTYear = this.filters.filterByDDTYear;
      ctx.filterByInvoiceYear = this.filters.filterByInvoiceYear;
      ctx.filterByDocumentType = this.filters.filterByDocumentType;
      ctx.filterByText = this.filters.filterByText;
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .acquireInvoicesPaths(ctx)
            .then(async res => {
              let invoices = res.items;
              for (let n = 0; n < invoices.length; n++) {
                let invoice = invoices[n];
                await usersManager.keepAlive();
                if (invoice.isNC) {
                  await ordersManager.printCN(
                    invoice.doc_number,
                    invoice.doc_year,
                    this.$store.state.user.locale,
                    true
                  );
                } else {
                  await ordersManager.printInvoice(
                    invoice.doc_number,
                    invoice.doc_year,
                    this.$store.state.user.locale,
                    true
                  );
                }
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadReportXMLZip() {
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByInvoiceState = this.filters.filterByInvoiceState;
      ctx.filterByInvoiceNumbers = this.filters.filterByInvoiceNumbers;
      ctx.filterByInvoiceYear = this.filters.filterByInvoiceYear;
      ctx.filterByDDTNumbers = this.filters.filterByDDTNumbers;
      ctx.filterByDDTYear = this.filters.filterByDDTYear;
      ctx.filterByDocumentType = this.filters.filterByDocumentType;
      ctx.filterByText  = this.filters.filterByText;
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadInvoicesXMLZip(ctx)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadInvoice(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadInvoice(
              item.doc_number,
              item.doc_year,
              this.$store.state.user.locale
            )
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadInvoiceDetail(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadInvoiceDetail(
              item.doc_number,
              item.doc_year,
              this.$store.state.user.locale
            )
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadCN(item, fromCN) {
      let doc_number = item.doc_number;
      let doc_year = item.doc_year;
      if (!fromCN) {
        doc_number = item.ref_doc_number;
        doc_year = item.ref_doc_year;
      }
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadCN(doc_number, doc_year, this.$store.state.user.locale)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadInvoiceXml(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadInvoiceXml(
              item.doc_number,
              item.doc_year,
              this.$store.state.user.locale
            )
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadCNXml(item, fromCN) {
      let doc_number = item.doc_number;
      let doc_year = item.doc_year;
      if (!fromCN) {
        doc_number = item.ref_doc_number;
        doc_year = item.ref_doc_year;
      }

      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadCNXml(doc_number, doc_year, this.$store.state.user.locale)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    printInvoice(item, spooler) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .printInvoice(
              item.doc_number,
              item.doc_year,
              this.$store.state.user.locale,
              spooler
            )
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    rebuildRange(range) {
      this.building_invoices = true;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "invoicesmng-building-invoices"
      );

      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .rebuildDocument(0, null, this.invoices[0].doc_year, range)
            .then(() => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
            })
            .catch(err => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
              console.log(err);
            });
        })
        .catch(err => {
          this.building_invoices = false;
          this.$store.state.global_overlay = false;
          console.log(err);
        });
    },

    rebuildDocument(item) {
      this.building_invoices = true;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "invoicesmng-building-invoices"
      );

      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .rebuildDocument(item.cn, item.doc_number, item.doc_year)
            .then(() => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
            })
            .catch(err => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
              console.log(err);
            });
        })
        .catch(err => {
          this.building_invoices = false;
          this.$store.state.global_overlay = false;
          console.log(err);
        });
    },

    printCN(item, fromCN, spooler) {
      let doc_number = item.doc_number;
      let doc_year = item.doc_year;
      if (!fromCN) {
        doc_number = item.ref_doc_number;
        doc_year = item.ref_doc_year;
      }

      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .printCN(
              doc_number,
              doc_year,
              this.$store.state.user.locale,
              spooler
            )
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    setSelectedAsPayed(mode) {
      this.$refs.msgBox
        .show(
          this.$t("invoicesmng-set-selection-confirm"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          let orders = [];
          this.selectedInvoices.map(x => {
            let items = x.orders_id.split(",");
            orders = [...orders, ...items];
          });
          ordersManager
            .changeInvoicePaymentStatus(orders.join(","), mode)
            .then(() => {
              this.fetchInvoices();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    setSelectedAsNotPayed() {
      this.$refs.msgBox
        .show(
          this.$t("invoicesmng-set-selection-confirm"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          let orders = [];
          this.selectedInvoices.map(x => {
            let items = x.orders_id.split(",");
            orders = [...orders, ...items];
          });
          ordersManager
            .changeInvoicePaymentStatus(orders.join(","), 0)
            .then(() => {
              this.fetchInvoices();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    setSelectedAsVoid() {
      this.$refs.msgBox
        .show(
          this.$t("invoicesmng-set-selection-confirm"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          let orders = [];
          this.selectedInvoices.map(x => {
            let items = x.orders_id.split(",");
            orders = [...orders, ...items];
          });
          ordersManager
            .changeInvoicePaymentStatus(orders.join(","), 5)
            .then(() => {
              this.fetchInvoices();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    setSelectedAsCompleteLoss() {
      this.$refs.msgBox
        .show(
          this.$t("invoicesmng-set-selection-confirm"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          let orders = [];
          this.selectedInvoices.map(x => {
            let items = x.orders_id.split(",");
            orders = [...orders, ...items];
          });
          ordersManager
            .changeInvoicePaymentStatus(orders.join(","), 8)
            .then(() => {
              this.fetchInvoices();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    }
  }
};
</script>
